import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/particular.css";

function Particular() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="contactLeftBottomTitle">
          <div className="term-h1">
            <h1>特定商取引法に基づく表記</h1>
          </div>
          <div className="rte">
            <div className="ko_none">
              <div className="scrollable-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <th>販売業者</th>
                      <td>株式会社 CURUCURU　（キュルキュル）</td>
                    </tr>
                    <tr>
                      <th>運営統括責任者名</th>
                      <td>時田由美子</td>
                    </tr>
                    <tr>
                      <th>郵便番号</th>
                      <td>460-0002</td>
                    </tr>
                    <tr>
                      <th>住所</th>
                      <td>
                        愛知県名古屋市中区丸の内1丁目17番19号　キリックス丸の内ビル
                        7F
                      </td>
                    </tr>
                    <tr>
                      <th>商品代金以外の料金の説明</th>
                      <td>
                        (1)　送料　全国一律470円　　　　(北海道・沖縄・離島900円)
                        <br />
                        　　　
                        <strong>
                          → 15,000円以上商品お買い上げで、送料無料
                        </strong>
                        <br />
                        <br />
                        (2)　銀行振り込みご購入の際の、振込手数料
                        <br />
                        <br />
                        ※代金引換でご購入の際の、代引手数料は無料
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>申込有効期限</th>
                      <td>
                        注文日を含め5日以内に決済が完了しない場合、キャンセル扱いといたします
                        <br />
                        ※代引きは除く
                      </td>
                    </tr>
                    <tr>
                      <th>不良品</th>
                      <td>
                        商品に不備があった場合、商品到着後7日以内に下記のフォームよりご連絡ください。ご連絡が7日以内に行われなかった場合、返品・交換を受け付けられない場合がありますので予めご了承ください。{" "}
                        <br />
                        当店の不手際により不備が発生した場合は、当店にて送料を負担し、返品・交換対応をいたします。
                      </td>
                    </tr>

                    <tr>
                      <th>引渡し時期</th>
                      <td>
                        在庫があるものに関しては、
                        <strong>
                          午後14時に入金確認できたご注文を当日出荷
                        </strong>
                        しております。
                        <br />
                        ただし、ご注文集中や欠品など、諸事情により前後する事がございます。
                        <br />
                        各商品ページに、お届け目安を掲載していますのでご確認ください。
                        <br />
                        <br />
                        ・営業日は、<strong>右下の営業カレンダー</strong>
                        をご覧ください。
                        <br />
                        ・コンビニ支払い（前払い）／銀行振込の場合は
                        <strong>入金確認後の発送</strong>となります。
                        <br />
                        ・受注生産品は注文から2ヶ月以内に発送いたします。
                        <br />
                        ・商品のお届け予定は事前にメールにてご連絡します
                        <br />
                        ・原則、クロネコヤマトでお届けします
                        <br />
                        <br />
                        【お急ぎの場合は・・・】
                        <br />
                        注文時に備考にお書き添えいただくか、お気軽にお問い合わせください。
                        <br />
                        <br />
                        【配送日のご指定について】
                        <br />
                        ご注文日より<strong>二週間以内のご指定</strong>
                        でお願いいたします。
                        <br />
                        二週間を超える場合は、下記３点をご了承頂ける場合のみお受けできます。
                        <br />
                        ・銀行振込にて事前にご入金ください
                        <br />
                        ・ご注文のキャンセルはお受けできません
                        <br />
                        ・全ての商品において、ご返品・ご交換は出来かねます（商品不良を除く）
                      </td>
                    </tr>
                    <tr>
                      <th>お支払い方法</th>
                      <td>
                        (1)クレジット　（VISA/MASTER/AMEX/JCB/DINERS)
                        <br />
                        (2)コンビニ支払い（前払い）
                        <br />
                        　（セブンイレブン/ファミリーマート/ローソン/ミニストップ/セイコーマート）
                        <br />
                        (3)代金引換　（現金/クレジットカード/デビッド）
                        <br />
                        (4)銀行振込　（三菱東京UFJ / 三井住友）　※前払い
                        <br />
                        <br />
                        詳しくは、<a href="#">こちら</a>
                        をご覧ください。
                      </td>
                    </tr>
                    <tr>
                      <th>お支払い期限</th>
                      <td>
                        (1)クレジットカード：ご注文時に画面の指示に従いご決済ください
                        <br />
                        (2)コンビニ支払い（前払い）：　注文日を含め、5日以内にご決済ください
                        <br />
                        (3)代金引換(現金/カード)：　商品配達時に配達員に直接お支払いください
                        <br />
                        (4)銀行振込：　注文日を含め、5日以内にご決済ください（前払い）
                      </td>
                    </tr>
                    <tr>
                      <th>返品期限</th>
                      <td>
                        商品到着後4日以内に、下記の返品受付フォームより連絡ください。また、8日以内にご返送をお願いします。次項の「返品・交換送料」項も合わせてご覧ください。
                        <br />
                        <br />
                        （返品・交換をお受けできないもの）
                        <br />
                        　1)　事前にご連絡なく、当店へ到着した商品
                        <br />
                        　2)　商品到着後、5日以上が経過した商品
                        <br />
                        　3)　8日以内にご返送がない場合
                        <br />
                        　4)　受注生産品・メーカー取り寄せ品
                        <br />
                        　5)　一度ご使用になられた商品 <br />
                        　6)　お客様のもとで商品、タグに汚損が生じた商品
                        <br />
                        　7)　セール商品やアウトレット商品
                        <br />
                        　8)　タグを破損・紛失された商品
                        <br />
                        <br />
                        ★商品のご返送は、弊社からの返答を待ってから発送をお願いいたします。
                        <br />
                        <br />
                        ★返品を連続して幾度と繰り返される場合には、当ショップのご利用自体をお断りする場合がございます。
                        <br />
                        <br />
                        ★クーリングオフについて
                        <br />
                        『クーリングオフ』は、訪問販売等に適用される制度です。当方をはじめ通信販売にはクーリングオフ制度は適用されませんので、あらかじめご了承ください。
                      </td>
                    </tr>
                    <tr>
                      <th>返品送料</th>
                      <td>
                        （返品・交換にかかる送料・振込み手数料）
                        <br />
                        お客様都合による返品・交換の場合は、お客様に実費ご負担いただきます。
                        <br />
                        ※ご交換品は着払いにてお送りさせていただきます。
                        <br />
                        初回出荷時の送料・代引手数料などは、ご返金できませんのでご注意ください。
                        <br />
                        <br />
                        ★返品により送料無料金額(商品合計15,000円)を下回る場合は、初回出荷時の送料470円をご負担いただくことになります(離島900円)
                        <br />
                        <br />
                        ★不良品・品違いの場合は、かかる費用は、当方負担とさせていただきます
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>屋号またはサービス名</th>
                      <td>HIGUMA GOLF STORE</td>
                    </tr>
                    <tr>
                      <th>電話番号</th>
                      <td>
                        TEL：　052-212-6852　　　FAX：　052-212-6802
                        <br />
                        ※ご連絡は、メールもしくはサイトの「問い合わせフォーム」をご利用願います
                      </td>
                    </tr>
                    <tr>
                      <th>公開メールアドレス</th>
                      <td>electron@occurel.xyz</td>
                    </tr>
                    <tr>
                      <th>ホームページアドレス</th>
                      <td>https://www.occurel.xyz</td>
                    </tr>
                  </tbody>
                </table>
                <div id="payment_method" className="sk_box">
                  <h2>お支払い方法</h2>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th></th>
                        <td>
                          <h4>クレジットカード</h4>
                          <p>
                            <img
                              alt=""
                              className="payment_img"
                              src="https://img.shop-pro.jp/common/card2.gif"
                            />
                            <img
                              alt=""
                              className="payment_img"
                              src="https://img.shop-pro.jp/common/card6.gif"
                            />
                            <img
                              alt=""
                              className="payment_img"
                              src="https://img.shop-pro.jp/common/card18.gif"
                            />
                            <img
                              alt=""
                              className="payment_img"
                              src="https://img.shop-pro.jp/common/card0.gif"
                            />
                            <img
                              alt=""
                              className="payment_img"
                              src="https://img.shop-pro.jp/common/card9.gif"
                            />
                            <font color="#ff0000">
                              <b>
                                ★重要★
                                当ショップでは【ボーナス一括払い】はご利用いただけません。
                              </b>
                            </font>
                            <br />
                            <br />
                            クレジットカード払いはこちらをお選びください。
                            <br />
                            <br />
                            VISA/MASTER/AMEX/JCB/DINERS/がご利用頂けます（リボ・分割可)。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <h4>コンビニ支払い（前払い）</h4>
                          <p>
                            【ご利用可能店舗】セブンイレブン／ファミリマート／ローソン／ミニストップ／セイコーマート
                            <br />
                            <br />
                            ご注文日を含めて5日以内にお振り込みをお願いします。午後14時までの入金確認後、商品を発送いたします。
                            <br />
                            <br />
                            ※各店舗でのお支払い方法・お支払いに必要なお支払い番号は、【electron@occurel.xyz】よりメールにてご案内差し上げます。
                            <br />
                            ※期日内のお振込が困難な場合はお知らせ下さい。
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <h4>代金引換 （クロネコヤマト）</h4>
                          <p>
                            配送業者（クロネコヤマト）が商品をお届け時に、代金をお支払いいただく決済方法です。前払いが不安な方にも安心です。
                            <br />
                            <br />
                            ●代引き手数料　 無料
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <h4>銀行振込（前払い）＿三菱UFJ銀行</h4>
                          <p>
                            <b>三菱UFJ銀行</b>
                            　名古屋駅前支店(ナゴヤエキマエシテン)　普通　6624277
                            <br />
                            株式会社 ＣＵＲＵＣＵＲＵ　　※ カ）キユルキユル
                            <br />
                            <br />
                            <span className="impact">
                              ご注文日を含めて5日以内に
                            </span>
                            お振り込みをお願いします。
                            <br />
                            午後14時までの入金確認後、商品発送いたします。
                            <br />
                            <br />
                            ※会社名でのお振込や、期日内のお振込が困難な場合はお知らせ下さい
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <h4>銀行振込（前払い）＿三井住友銀行</h4>
                          <p>
                            <b>三井住友銀行</b>
                            　上前津支店(カミマエヅシテン)　普通　7354694
                            <br />
                            株式会社 ＣＵＲＵＣＵＲＵ　　※ カ）キユルキユル
                            <br />
                            <br />
                            <span className="impact">
                              ご注文日を含めて5日以内に
                            </span>
                            お振り込みをお願いします。
                            <br />
                            午後14時までの入金確認後、商品発送いたします。
                            <br />
                            <br />
                            ※会社名でのお振込や、期日内のお振込が困難な場合はお知らせ下さい
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>
                          <h4>PayPay（残高払い）</h4>
                          <p>
                            スマホ決済サービス「PayPay」にてお支払いいただく決済方法です。
                            <br />
                            ご注文後すぐに「PayPay」の決済画面が表示されますので、画面に表示される指示に従いお支払いください。
                            <br />
                            <br />
                            <font color="red">
                              ※paypay残高による一括払いのみとなり、
                              <b>
                                残高不足の状態ではお支払い手続きを正常に完了いただけません。
                              </b>
                              事前にpaypayアプリにて残高が十分に存在するかご確認ください。
                            </font>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="shipping_method" className="sk_box">
                  <h3>配送方法・送料について</h3>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th></th>
                        <td>
                          <h4>当方指定業者</h4>
                          <p>
                            <font size="2" color="#ff0000">
                              ①【宅配ボックスのご指定】
                              <br />
                              【建物名・部屋番号】の欄に
                              <b>
                                「★宅配ボックス希望」 or
                                「★不在時は、宅配ボックス希望」
                              </b>
                              とお書き下さい。
                            </font>
                            <br />
                            <img
                              alt="宅配ボックスの入力箇所"
                              src="https://shop.curucuru.jp/cart/deliverybox_input_sample.png"
                              width="320"
                            />
                            <br />
                            <font size="2">
                              ※代金引換の場合は宅配ボックスへお入れできません。
                            </font>
                            <br />
                            <br />
                            <font size="2" color="#ff0000">
                              ②【注文者】と【送付先】のご住所が違う場合
                              <u>
                                <b>「納品書」は同梱せずに発送 </b>
                              </u>
                              致します。
                            </font>
                            <br />
                            <br />
                            <font size="2">
                              ③配送業者：原則、クロネコヤマトでお届けします。
                              <br />
                              ※配送業者のご指定はお受けできません。
                              <br />
                              <br />
                              ④送料：全国一律470円　(北海道・沖縄・離島900円)
                              <br />
                              ※商品代金計15,000円以上で送料無料
                            </font>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Particular;
